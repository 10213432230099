import React, { Fragment, lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthGuard from 'src/components/AuthGuard';
import LoadingScreen from 'src/components/LoadingScreen';
import DashboardLayout from 'src/layouts/DashboardLayout';
import { pathJoin } from 'src/utilities/Utilities';
import NotFoundView from 'src/views/errors/NotFoundView';
import useAuth from './hooks/useAuth';
import useFeatureFlags from './hooks/useFeatureFlags';

const getRoutes = (featureFlags, user) => {
  const processRoutes = (routes, parent = {}) => {
    return routes.map((route) => {
      route.path = pathJoin(parent.path, route.path);
      route.guard = route.guard || parent.guard;

      route.accessible = isRouteAccessible(route, parent, user);

      if (route.routes) {
        route.routes = processRoutes(route.routes, route);
      }

      return route;
    });
  };

  const isRouteAccessible = (route, parent, user) => {
    if (user?.groups.indexOf('Lantern Admin') != -1 && (!route.featureFlags || !route.featureFlags(featureFlags))) {
      return true;
    }

    return isAccessible(parent, route, user);
  };

  const isAccessible = (parent, route, user) => {
    return parent?.accessible === false ? false : userHasAccessToRoute(route, user);
  };

  const userHasAccessToRoute = (route, user) => {
    return route.group ? userInGroup(route.group, user) : !route.featureFlags || !route.featureFlags(featureFlags);
  };

  const userInGroup = (group, user) => {
    return user?.groups.indexOf(group) != -1;
  };

  const objectify = (routes) => {
    let obj = {};

    routes.forEach((route) => {
      obj[route.key] = {
        route: {
          accessible: route.accessible,
          path: route.path,
        },
        ...(route.routes ? objectify(route.routes) : {}),
      };
    });

    return obj;
  };

  let arrayRoutes = processRoutes([
    {
      key: 'login',
      path: '/login',
      component: lazy(() => import('src/views/auth/LoginView/index')),
    },
    {
      key: 'error404',
      path: '/404',
      component: NotFoundView,
    },
    {
      key: 'error403',
      path: '/403',
      component: lazy(() => import('src/views/errors/ForbiddenView')),
    },
    {
      key: 'timedOut',
      path: '/timed-out',
      component: lazy(() => import('src/views/errors/TimedOutView')),
    },
    {
      key: 'clientReview',
      path: '/client-review/client/:parentClient',
      layout: DashboardLayout,
      routes: [
        {
          key: 'clientReviewList',
          path: '/',
          component: lazy(() => import('src/views/content/clientReview/index')),
        },
        {
          key: 'clientReviewEdit',
          path: '/channelProduct/:channelProductId/:placeHolder',
          component: lazy(() => import('src/views/content/contentEditing/index')),
        },
        {
          key: 'clientAssetManager',
          path: '/assetManager/:placeHolder',
          component: lazy(() => import('src/views/assetManager/index')),
        },
      ],
    },
    {
      key: 'app',
      path: '/',
      guard: AuthGuard,
      layout: DashboardLayout,
      routes: [
        {
          key: 'home',
          path: '/',
          component: lazy(() => import('src/views/home/HomeView')),
        },
        {
          key: 'products',
          path: '/products',
          permissions: [{ tool: 'productspage', permissions: 'read' }],
          group: 'Harvest Employees',
          routes: [
            {
              key: 'list',
              path: '/',
              component: lazy(() => import('src/views/products/ProductListView/index')),
              group: 'Harvest Employees',
            },
            {
              key: 'create',
              path: '/create',
              component: lazy(() => import('src/views/products/NewProduct/index')),
              group: 'Harvest Employees',
            },
          ],
        },
        {
          key: 'content',
          path: '/content',
          group: 'Harvest Employees',
          routes: [
            {
              key: 'list',
              path: '/',
              component: lazy(() => import('src/views/content/mainPage/index')),
              group: 'Harvest Employees',
            },
            {
              key: 'contentCreation',
              path: '/content-creation',
              group: 'Harvest Employees',
              routes: [
                {
                  key: 'keywording',
                  path: '/keywording',
                  component: lazy(() => import('src/views/keywording/ProductKeywordingView/index')),
                  group: 'Harvest Employees',
                },
                {
                  key: 'copywriting',
                  path: '/copywriting/channelProduct/:channelProductId',
                  component: lazy(() => import('src/views/content/contentEditing/index')),
                  group: 'Harvest Employees',
                },
              ],
            },
          ],
        },
        {
          key: 'clientConfiguration',
          path: '/client-configuration',
          component: lazy(() => import('src/views/content/clientConfiguration/index')),
          group: 'Harvest Employees',
        },
        {
          key: 'retailerConfiguration',
          path: '/retailer-configuration',
          component: lazy(() => import('src/views/retailerConfiguration/index')),
          group: 'lantern_Content_Admin',
        },
        {
          key: 'assetManager',
          path: '/asset-manager',
          component: lazy(() => import('src/views/assetManager/index')),
          group: 'Harvest Employees',
        },
        {
          key: 'enhancedContent',
          path: '/enhanced-content',
          group: 'Lantern Content Specialists',
          routes: [
            {
              key: 'list',
              path: '/',
              component: lazy(() => import('src/views/content/enhancedContent/index')),
              group: 'Lantern Content Specialists',
            },
            {
              key: 'marketingContent',
              path: '/marketing-content',
              component: lazy(() => import('src/views/content/enhancedContent/components/marketingContent/MarketingContentView')),
              group: 'Lantern Content Specialists',
            },
          ],
        },
        {
          key: 'keywordManager',
          path: '/keyword-manager',
          component: lazy(() => import('src/views/content/keywordManager/index')),
          group: 'Harvest Employees',
        },
        {
          key: 'quickKeywording',
          path: '/quick-keywording',
          component: lazy(() => import('src/views/quickKeywording/index')),
          group: 'Harvest Employees',
        },
        {
          key: 'emberDatasetManager',
          path: '/ember-dataset-manager',
          component: lazy(() => import('src/views/emberDataManagementTools/emberDatasetManager/index')),
          group: 'Harvest Employees',
        },
        {
          key: 'clientGoalsTracker',
          path: '/client-goals-tracker',
          component: lazy(() => import('src/views/emberDataManagementTools/clientGoalsTracker/index')),
          group: 'Harvest Employees',
        },
        {
          key: 'permissions',
          path: '/permissions',
          component: lazy(() => import('src/views/permissions/index')),
          group: 'Harvest Employees',
          featureFlags: (featureFlags) => featureFlags.disableUserAlignmentView,
        },
        {
          key: 'forecastRoundtableTool',
          path: '/forecast-roundtable-tool',
          component: lazy(() => import('src/views/forecastRoundtable/index')),
          group: 'Harvest Employees',
          featureFlags: (featureFlags) => featureFlags.disableForecastRoundtableTool,
        },
        {
          key: 'forecastRoundtableManager',
          path: '/forecast-roundtable-manager',
          component: lazy(() => import('src/views/forecastRoundtableManager/index')),
          group: 'Lantern Forecast Roundtable Managers Security',
          featureFlags: (featureFlags) => featureFlags.disableForecastRoundtableManager,
        },
        {
          key: 'netsuiteToForemanClientMappingTool',
          path: '/netsuite-foreman-client-mapping-tool',
          component: lazy(() => import('src/views/netsuiteToForemanClientMapping/index')),
          group: 'Lantern Forecast Roundtable Editors Security',
          featureFlags: (featureFlags) => featureFlags.disableNetsuiteToForemanClientMappingTool,
        },
        {
          key: 'pbcsFileUploader',
          path: '/pbcs-file-uploader',
          component: lazy(() => import('src/views/PBCSFileUploader/index')),
          group: 'PBCS Export Uploaders',
          featureFlags: (featureFlags) => featureFlags.disablePBCSFileUploader,
        },
      ],
    },
  ]);

  const objectRoutes = objectify(arrayRoutes);

  return {
    routes: arrayRoutes,
    objectRoutes,
  };
};

const Routes = ({ routes, objectRoutes }) => {
  const { featureFlags, featureFlagsLoaded } = useFeatureFlags();
  const { user } = useAuth();

  if ((!routes || !objectRoutes) && featureFlagsLoaded) {
    const rootRoutes = getRoutes(featureFlags, user);
    routes = rootRoutes.routes;
    objectRoutes = rootRoutes.objectRoutes;
  }

  return (
    <Suspense fallback={<LoadingScreen />}>
      {featureFlagsLoaded ? (
        <Switch>
          {routes?.map((route, i) => {
            const Guard = route.guard || Fragment;
            const Layout = route.layout || Fragment;
            const Component = route.component;
            return (
              <Route
                key={i}
                path={route.path}
                exact={!route.routes}
                render={(props) => (
                  <Guard route={route}>
                    <Layout routes={objectRoutes}>
                      {route.routes ? (
                        <Routes routes={route.routes} objectRoutes={objectRoutes} />
                      ) : (
                        <Component route={route} {...props} />
                      )}
                    </Layout>
                  </Guard>
                )}
              />
            );
          })}
          <Route>
            <NotFoundView />
          </Route>
        </Switch>
      ) : (
        <LoadingScreen />
      )}
    </Suspense>
  );
};

export default Routes;
